import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import './assets/css/index.css';
import "../src/assets/css/SQ_247_PMRS_V1/input.css";


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <App />
);