import React, { useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { RedirectProvider } from "./Contexts/RedirectContext";
// import Layout from "./Layout";
import GtmScript from "../src/Utility/GtmScript";
import AdtopiaLoadDynamic from "./Utility/AdtopiaLoadDynamic";
const HomePage = lazy(() => import("../src/Components/Pages/HomePage"));
const RedirectWithQueryParams = lazy(() => import("./Utility/RedirectWithQueryParams"));
const SQ_247_PMRS_V1= lazy(() => import("../src/Components/Pages/Splits/SQ_247_PMRS_V1"));
const SQ_247_PMRS_SPH_1= lazy(() => import("../src/Components/Pages/Splits/SQ_247_PMRS_SPH_1"));

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: '' });
  return (
    <>
    <VisitorParamsProvider value={{ visitorParameters }}>
      <RedirectProvider value={{ redirectUrl }}>
        <BrowserRouter>
          <Suspense fallback={<div></div>}>
            <Switch>
              {/* <Layout> */}
                <Route exact path="/" component={HomePage} />
                <Route exact path="/SQ_247_PMRS_V1" component={SQ_247_PMRS_V1} />
                <Route exact path="/SQ_247_PMRS_SPH_1" component={SQ_247_PMRS_SPH_1} />
                <Route exact path={["/google","/testing"]} component={AdtopiaLoadDynamic}/>
                <Route path="*"><RedirectWithQueryParams/></Route>
              {/* </Layout> */}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </RedirectProvider>
    </VisitorParamsProvider>
    <GtmScript/>
    </>
  );
};

export default App;
